import React from 'react'
import { graphql } from 'gatsby'

import { Button, Column, Layout, Row, Text } from '../../'
import { formatDates, getFio } from '../../../utils'
import { TEXTS } from '../../../constants'
import Img from 'gatsby-image'

import songsIcon from '../../../images/icons/songs.svg'
import importantIcon from '../../../images/icons/important.svg'

import styles from './hero-template.module.css'

export default ({ data, path }) => {
    const { hero } = data
    const { photo, additionalPhoto, firstName, lastName, middleName, bio, birth, death, important, rank } = hero
    const fio = getFio(lastName, firstName, middleName)
    const usingPhoto = additionalPhoto || photo

    return (
        <Layout pageTitle={fio}>
            <Row>
                <Column width={30}>
                    <Img fluid={usingPhoto.localFile.childImageSharp.fluid} alt={fio} />
                    <Button to="songs" text={TEXTS.songs} icon={songsIcon} />
                    {important && <Button to={`${path}/important`} text={TEXTS.important} icon={importantIcon} />}
                </Column>
                <Column width={70}>
                    <div className={styles.info}>
                        <p className={styles.rank}>{rank.rank}</p>
                        <h1 className={styles.fio}>
                            <span className={styles.lastName}>{lastName}</span><br />
                            {`${firstName} ${middleName}`}
                        </h1>
                        <p className={styles.lifeDates}>{formatDates(birth, death)}</p>
                    </div>
                    {bio && <Text content={bio.json} />}
                </Column>
            </Row>
        </Layout>
    )
}

export const query = graphql`
  query($id: String!) {
    hero: contentfulHero(id: {eq: $id}) {
      firstName
      lastName
      middleName
      birth(locale: "RU-ru")
      death(locale: "RU-ru")
      birthPlace {
        index
        title
      }
      photo {
        localFile {
          childImageSharp {
            fluid(cropFocus: CENTER, fit: COVER, grayscale: true, maxHeight: 580, maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      additionalPhoto {
        localFile {
          childImageSharp {
            fluid(cropFocus: CENTER, fit: COVER, grayscale: true, maxHeight: 580, maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      rank {
        rank
      }
      specialization {
        name
      }
      bio {
        json
      }
      important {
        json
      }
    }
  }
`
